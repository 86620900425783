<template>
  <section class="invoice-add-wrapper">
    <b-tabs pills v-model="tabIndex2" no-body>
      <hr class="m-1" />
      <div>
        <b-alert show variant="primary">
          <h4 class="alert-heading">
            Invoice No:
            <b-badge variant="primary">{{
              invoiceData.invoice_unique_id ? invoiceData.invoice_unique_id : ""
            }}</b-badge>
            / Invoice Amount:
            <b-badge variant="primary"
              >{{ invoiceData.selectedCurrency }}
              {{ invoiceData.invoice_total }}</b-badge
            >/ Amount Due:
            <b-badge variant="primary"
              >{{ invoiceData.selectedCurrency }}
              {{
                parseFloat(invoiceData.invoice_total).toFixed(2) -
                parseFloat(
                  invoiceData.received_amount ? invoiceData.received_amount : 0
                ).toFixed(2)
              }}</b-badge
            >
            / IBAN:
            <b-badge variant="primary">{{ invoiceData.iban }}</b-badge>
          </h4>
        </b-alert>
      </div>
      <!-- TAB 1 -->
      <b-tab title="Invoice">
        <b-row>
          <b-col md="8" offset-md="2">
            <div class="text-center mb-1">
              <b-button-group size="sm">
                <b-button
                  @click="onClickLangPdf(pdf)"
                  v-for="(pdf, index) in invoiceData.invoice_doc.split(',')"
                  :key="index"
                  size="sm"
                  variant="outline-info"
                  >{{
                    pdf.split("--")[0] == "EN"
                      ? "English Preview"
                      : pdf.split("--")[0] == "FR"
                      ? "French Preview"
                      : pdf.split("--")[0] == "DE"
                      ? "German Preview"
                      : ""
                  }}</b-button
                >
              </b-button-group>
            </div>
            <!-- <b-card border-variant="primary"> -->
            <b-embed
              v-if="previewPdf"
              type="iframe"
              aspect="16by9"
              :src="previewPdf"
              allowfullscreen
            ></b-embed>

            <!-- <vue-pdf-embed
                  ref="myPdfComponent"
                  v-if="previewPdf"
                  :source="previewPdf"
                />  -->
            <!-- <embed :src="previewPdf" style="height: 1000px; width: 100%;" type="application/pdf"> -->
            <!-- </b-card> -->
            <!-- <div class="text-center">
              <b-badge
                pill
                class="mb-1 invoice-button"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                @click="$refs.myPdfComponent.print()"
              >
                Download Invoice
              </b-badge>
            </div> -->
          </b-col>
        </b-row>

        <!-- <b-col cols="12" md="3">
            <b-button
              size="sm"
              class="mb-1 mr-1 invoice-button"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              @click="$refs.myPdfComponent.print()"
            >
              Download Invoice
            </b-button>
            
          </b-col> -->

        <!-- <b-button
              size="sm"
              class="mb-1 mr-1 invoice-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-toggle.sidebar-send-invoice
              variant="outline-warning"
            >
              Send Invoice
            </b-button> -->
        <!-- </b-row> -->
      </b-tab>

      <!-- TAB 2 -->
      <b-tab title="Payment Details / Add Maunal Payment / Write off Amount">
        <b-row>
          <b-col md="3">
            <b-card>
              <b-form-group label="Payment Type">
                <v-select
                  v-model="paymentData.paymentMethod"
                  placeholder="Payment Type"
                  label="label"
                  :reduce="(label) => label.value"
                  :options="paymentTypeList"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                />
              </b-form-group>
              <b-form-group label="Amount">
                <b-form-input
                  id="from"
                  v-model="paymentData.paymentAmount"
                  placeholder="Amount"
                  trim
                  type="number"
                />
              </b-form-group>
              <b-form-group label="Payment Date">
                <flat-pickr
                  v-model="paymentData.paymentDate"
                  placeholder="payment Date"
                  :config="{
                    ...$flatPickrConfig,
                  }"
                  class="form-control"
                />
              </b-form-group>
              <b-button
                class="mb-1 mr-1 invoice-button"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="success"
                @click="savePayment"
              >
                Submit
              </b-button>
            </b-card>
          </b-col>

          <b-col md="9">
            <div class="accordion" role="tablist">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="info"
                  >Payment Details</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-1"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <h5>
                  <b
                    >Note : Invoice Payment Status can take upto 1 min to
                    reflect after Add/Upload</b
                  >
                </h5>
                <b-card-body>
                  <b-card-text>
                    <table>
                      <tr>
                        <th>Payment Type</th>
                        <th>Paid Amount</th>
                        <th>Paid Date</th>
                        <th>Created At</th>
                        <th>Amount Linked with Invoice</th>
                        <th>Action</th>
                      </tr>
                      <tr
                        v-for="(item, index) in paidInvoiceBills"
                        :key="index"
                      >
                        <td>
                          {{
                            item.payment_method == "1"
                              ? "Manual"
                              : item.payment_method == "2"
                              ? "Write Off (+)"
                              : item.payment_method == "3"
                              ? "Write Off (-)"
                              : item.payment_method == "4"
                              ? "Storno/Credit Note"
                              : "Bank Statement"
                          }}
                        </td>
                        <td>
                          {{ invoiceData.selectedCurrency }}
                          {{ item.paidAmount }}
                        </td>
                        <td>
                          {{ moment(item.paidAmountDate).format("DD/MM/YYYY") }}
                        </td>
                        <td>
                          {{ moment(item.createdAt).format("DD/MM/YYYY") }}
                        </td>
                        <td>
                          {{ item.paymentLinked == 1 ? "YES" : "NO" }}
                        </td>
                        <td>
                          <b-button
                            @click="
                              () => {
                                showModal(
                                  'Are you sure want to delete this payment?',
                                  'delete'
                                ).then((value) => {
                                  if (value)
                                    onDeletePaymentItem(item.invoice_id);
                                });
                              }
                            "
                            variant="outline-danger"
                            size="sm"
                            >Delete</b-button
                          >
                        </td>
                      </tr>
                    </table></b-card-text
                  >
                </b-card-body>
              </b-collapse>

              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  @click="() => (showRev = true)"
                  block
                  v-b-toggle.accordion-2
                  variant="info"
                  >Revenue Payment Dates</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <view-added-revenue-page
                      isPaymentPage="Y"
                      :invNumber="invoiceData.invoice_unique_id"
                    />
                  </b-card-text>
                </b-card-body>
              </b-collapse>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  @click="() => (showAddi = true)"
                  block
                  v-b-toggle.accordion-3
                  variant="info"
                  >Additional Revenue Payment Dates</b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    ><view-additional-cost-revenue
                      isPaymentPage="Y"
                      :invNumber="revenue_invoice_id"
                  /></b-card-text>
                </b-card-body>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <invoice-sidebar-send-invoice
      :id="this.revenue_invoice_id"
      type="RevenueInvEmail"
    />
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import showModal from "../../components/ModalConfirmation";
import ViewAddedRevenuePage from "@/views/pages/Revenue/RevenuePages/ViewAddedRevenuePage.vue";
import ViewAdditionalCostRevenue from "@/views/pages/Revenue/RevenuePages/ViewAdditionalCostRevenue.vue";

import {
  GetRevenueInvoiceById,
  SavePaymentHistory,
  DeletePaymentItem,
} from "@/apiServices/RevenueServies";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BButtonGroup,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BTableLite,
  BTable,
  BBadge,
  BEmbed,
  BCollapse,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import InvoiceSidebarSendInvoice from "./InvoiceSidebarSendInvoice.vue";

export default {
  props: {
    invId: {
      type: Number,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BButtonGroup,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    BTableLite,

    ToastificationContentVue,
    InvoiceSidebarSendInvoice,
    BTable,
    BBadge,
    BEmbed,
    BCollapse,
    ViewAddedRevenuePage,
    ViewAdditionalCostRevenue,
    // InvoiceSidebarAddPayment,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  data() {
    return {
      tabpayment: null,
      paymentTypeList: [
        {
          label: "Manual",
          value: "1",
        },
        {
          label: "Write Off (+)",
          value: "2",
        },
        {
          label: "Write Off (-)",
          value: "3",
        },
        // {
        //   label: "Storno/Credit Note",
        //   value: "4",
        // },
      ],
      paymentData: {
        paymentMethod: null,
        paymentAmount: null,
        paymentDate: null,
      },
      dateForRoyaltyReport: null,
      receivedAmountDate: null,
      BASE_URL_UPLOAD: process.env.VUE_APP_FILESURL + "/",
      revenue_invoice_id: null,
      invoiceData: null,
      previewPdf: null,
      billedRevenue: [],
      paidInvoiceBills: [],
      //old
      moment: moment,

      otherChargesArray: [],
      subTotal: 0,
      totalOtherCharge: 0,
      exchangeRate: 1,
      vatPercentage: 0,
      fieldsPaidInvoiceBills: [
        {
          key: "payableAmount",
          label: "Amount Paid",
        },

        {
          key: "payableAmountDate",
          label: "Payment Date",
        },
        {
          key: "revenue_royalty_date_new",
          label: "Date for Royalty Report",
        },
      ],
      fieldsBilled: [
        {
          key: "rightIdentifier",
          label: "Right",
        },
        {
          key: "share_net",
          label: "Total Invoice Amount (CHF)",
        },
        {
          key: "amount_paid",
          label: "Amount Paid ",
        },
        {
          key: "pending_amount",
          label: "Pending Amount (CHF)",
        },

        {
          key: "right_type",
          label: "Amount (CHF)",
        },
        {
          key: "unit_sold",
          label: "Payment Date",
        },
        {
          key: "status",
          label: "Date for Royalty Report",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      showRev: false,
      showAddi: false,
      tabIndex2: 0,
    };
  },

  mounted() {
    let queryTabIndex = this.$route.query;
    if (queryTabIndex.hasOwnProperty("tabIndex")) {
      // setTimeout((z) => {
      //   this.tabIndex2 = +parseFloat(queryTabIndex.tabIndex);
      // }, 500);
    }
    console.log(queryTabIndex);
  },

  beforeMount() {
    this.revenue_invoice_id = this.$route.params.revenue_invoice_id
      ? this.$route.params.revenue_invoice_id
      : this.$props.invId
      ? this.$props.invId
      : null;
    this.getInvoiceById();
  },
  methods: {
    showModal,

    async onDeletePaymentItem(seletedItem) {
      try {
        const response = await DeletePaymentItem({
          pay_id: seletedItem,
        });

        if (response.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Deleted",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          this.getInvoiceById();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Not Deleted",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log("error in onDeletePaymentItem=>", error);
      }
    },
    onClickLangPdf(file) {
      this.previewPdf = this.BASE_URL_UPLOAD + file;
    },
    async getInvoiceById() {
      try {
        let payload = {
          revenue_invoice_id: this.revenue_invoice_id,
        };
        const response = await GetRevenueInvoiceById(payload);
        if (response.data.status) {
          this.invoiceData = response.data.data.invoice;

          this.paidInvoiceBills = response.data.data.paidInvoiceBills;

          if (this.$route.query.pdf) {
            this.previewPdf = this.BASE_URL_UPLOAD + this.$route.query.pdf;
          } else {
            if (
              this.invoiceData.invoice_doc &&
              this.invoiceData.invoice_doc.split(",").length > 1
            ) {
              this.previewPdf =
                this.BASE_URL_UPLOAD +
                this.invoiceData.invoice_doc.split(",")[0];
            } else {
              this.previewPdf =
                this.BASE_URL_UPLOAD + this.invoiceData.invoice_doc;
            }
          }
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async savePayment() {
      try {
        let payload = {
          paymentData: this.paymentData,
          invoiceData: this.invoiceData,
        };
        const response = await SavePaymentHistory(payload);
        if (response.data.message) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Added Succesfully",
              icon: "EditIcon",
              variant: "primary",
            },
          });

          this.getInvoiceById();
        }
      } catch (err) {
        console.log("Error in Updating ", err);
      }
    },
    openPaymentSummary() {
      this.$refs["paymentSummaryModal"].show();
    },
    printPdf() {
      this.previewPdf;
    },
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-preview-tabs {
  margin-top: -4em;
}

.invoice-button {
  white-space: nowrap;
  text-align: center;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
